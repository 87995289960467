import * as UI from './TooltipContent.styles';
import { ITooltipContentProps } from './types';

const TooltipContent = (props: ITooltipContentProps) => {
  const { link, socials } = props;

  const copyUrlToClipboard = () => {
    navigator.clipboard?.writeText(link);
  };

  return (
    <UI.Container>
      <UI.Icons>
        {socials.map(({ id, Icon, href }) => (
          <UI.IconLink href={href} key={id} target="_blank">
            {Icon}
          </UI.IconLink>
        ))}
      </UI.Icons>

      <UI.LinkInput
        value={link}
      />

      <UI.CopyLink onClick={copyUrlToClipboard}>
        Скопировать ссылку
      </UI.CopyLink>
    </UI.Container>
  );
};

export default TooltipContent;
