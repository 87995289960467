import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import colors from '@core/constants/colors';

import { ITriggerSizes, TTooltipPlacement } from './types';

interface ITooltipContainerProps {
  isOpen: boolean;
  zIndex: number;
  placement: TTooltipPlacement;
  triggerSizes: ITriggerSizes;
}

const getPosition = (placement: TTooltipPlacement, triggerSizes: ITriggerSizes): FlattenSimpleInterpolation => {
  const { width, height } = triggerSizes;
  const margin = '10px';

  switch (placement) {
    case 'bottomLeft':
      return css`transform: translate(0, calc(${height} + ${margin}))`;
    case 'bottom':
      return css`transform: translate(calc(-50% + ${width}/2), calc(${height} + ${margin}))`;
    case 'bottomRight':
      return css`transform: translate(calc(-100% + ${width}), calc(${height} + ${margin}))`;

    case 'leftTop':
      return css`transform: translate(calc(-100% - ${margin}), 0)`;
    case 'left':
      return css`transform: translate(calc(-100% - ${margin}), calc(-50% + ${height}/2))`;
    case 'leftBottom':
      return css`transform: translate(calc(-100% - ${margin}), calc(-100% + ${height}))`;

    case 'topLeft':
      return css`transform: translate(0, calc(-100% - ${margin}))`;
    case 'top':
      return css`transform: translate(calc(-50% + ${width}/2), calc(-100% - ${margin}))`;
    case 'topRight':
      return css`transform: translate(calc(-100% + ${width}), calc(-100% - ${margin}))`;

    case 'rightTop':
      return css`transform: translate(calc(${width} + ${margin}), 0)`;
    case 'right':
      return css`transform: translate(calc(${width} + ${margin}), calc(-50% + ${height}/2))`;
    case 'rightBottom':
      return css`transform: translate(calc(${width} + ${margin}), calc(-100% + ${height}))`;

    default:
      return css``;
  }
};

export const TooltipWrapper = styled.div<{ styles?: FlattenSimpleInterpolation }>`
  position: relative;
  ${({ styles }) => styles};
`;

export const TooltipContainer = styled.div<ITooltipContainerProps>`
  position: absolute;
  background-color: ${colors.white};
  padding: 16px;
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 30%) 0 5px 10px;
  top: 0;
  left: 0;

  ${({ isOpen, zIndex, placement, triggerSizes }) => css`
    display: ${isOpen ? 'block' : 'none'};
    z-index: ${zIndex};
    ${getPosition(placement, triggerSizes)};
  `}
`;
