import { RefObject, useEffect, useState } from 'react';

import { ITriggerSizes } from '@core/UI/Tooltip/types';

export const useCloseOnOutsideClick = (
  optionsContainerRef: RefObject<HTMLDivElement>,
  isOptionsOpen: boolean,
  setIsOptionsOpen: (value: boolean) => void,
) => {
  useEffect(() => {
    if (optionsContainerRef.current && isOptionsOpen) {
      const element = optionsContainerRef.current;
      const outsideClickListener = (event: any) => {
        if (!element.contains(event.target)) {
          setIsOptionsOpen(false);
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          removeClickListener();
        }
      };
      document.addEventListener('click', outsideClickListener);
      const removeClickListener = () => {
        document.removeEventListener('click', outsideClickListener);
      };
      return removeClickListener;
    }
  }, [optionsContainerRef, isOptionsOpen, setIsOptionsOpen]);
};

export const useTriggerSizes = (wrapperRef: RefObject<HTMLDivElement>):ITriggerSizes => {
  const [triggerSizes, setTriggerSizes] = useState<ITriggerSizes>({ width: '0px', height: '0px' });

  useEffect(() => {
    const wrapper = wrapperRef.current;

    const resizeObserver = new ResizeObserver((entries) => {
      // Оборачиваем в вызов requestAnimationFrame во избежание ошибки - ResizeObserver loop limit exceeded
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }

        const { target } = entries[0];
        const { width = 0, height = 0 } = target.getBoundingClientRect();
        setTriggerSizes({ width: `${width}px`, height: `${height}px` });
      });

      if (wrapper) {
        resizeObserver.observe(wrapper);
      }

      return () => {
        if (wrapper) {
          resizeObserver.unobserve(wrapper);
        }
      };
    });
  }, [wrapperRef]);

  return triggerSizes;
};
