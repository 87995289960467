import ImageNext from 'next/image';
import styled from 'styled-components';

import { ICardStylesProps } from './types';

export const Card = styled.div<ICardStylesProps>`
  background-size: cover;
  border-radius: 12px;
  position: relative;
  z-index: 1;
  height: 100%;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0.32deg, rgba(0, 41, 80, 0.648) 31.15%, rgba(0, 41, 80, 0) 100%);
    border-radius: 12px;
    z-index: -1;
  }

  ${({ styles }) => styles}
`;

export const Image = styled(ImageNext).attrs({ fill: true })`
  z-index: -2;
  object-fit: cover;
`;
