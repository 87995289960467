import type { Ref, ChangeEvent } from 'react';
import { forwardRef } from 'react';

import * as UI from './Input.styles';
import { IInputProps } from './types';

const getInputElement = (type: IInputProps['type']) => {
  switch (type) {
    case 'landing':
      return UI.LandingInput;
    case 'default':
    default:
      return UI.Input;
  }
};

export const Input = forwardRef((props: IInputProps, ref: Ref<HTMLInputElement>) => {
  const {
    value,
    label,
    placeholder,
    error,
    onChange = () => {},
    name,
    htmlType = 'text',
    className,
    disabled,
    type = 'default',
    styles,
    onKeyDown = () => {},
  } = props;

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange(target.value);
  };

  const classNames = [className || '', error ? 'error' : '', disabled ? 'disabled' : ''].join(' ');

  const InputElement = getInputElement(type);

  return (
    <UI.InputContainer className={classNames} styles={styles}>
      {label && (
        <UI.InputLabel $type={type}>
          {label}
        </UI.InputLabel>
      )}

      <UI.InputWrapper>
        <InputElement
          disabled={disabled}
          name={name}
          placeholder={placeholder}
          ref={ref}
          type={htmlType}
          value={value}
          onChange={handleChange}
          onKeyDown={onKeyDown}
        />

        {error && (
          <UI.ErrorMessage>
            {error}
          </UI.ErrorMessage>
        )}
      </UI.InputWrapper>
    </UI.InputContainer>
  );
});
