import { debounce, DebouncedFunc } from 'lodash';
import { MouseEvent, useRef, useState } from 'react';

import * as UI from './Tooltip.styles';
import { useCloseOnOutsideClick, useTriggerSizes } from './hooks';
import { ITooltipProps } from './types';

const DEFAULT_DELAY = 300;

let debouncedLeave: DebouncedFunc<() => void> | null = null;

const Tooltip = (props: ITooltipProps) => {
  const {
    styles,
    children,
    tooltipContent,
    trigger = 'click',
    zIndex = 1,
    placement = 'bottom',
    enterDelay = DEFAULT_DELAY,
    leaveDelay = DEFAULT_DELAY,
  } = props;
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const triggerSizes = useTriggerSizes(tooltipRef);

  useCloseOnOutsideClick(tooltipRef, isOpen, setIsOpen);

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  const onMouseEnter = debounce(() => {
    setIsOpen(true);
    debouncedLeave?.cancel();
  }, enterDelay - 50);

  const onMouseLeave = () => {
    debouncedLeave = debounce(() => {
      setIsOpen(false);
    }, leaveDelay);
    debouncedLeave();
  };

  const onTooltipClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <UI.TooltipWrapper
      {...trigger === 'click' && { onClick }}
      {...trigger === 'hover' && { onMouseEnter, onMouseLeave }}
      ref={tooltipRef}
      styles={styles}
    >
      {children}

      <UI.TooltipContainer
        isOpen={isOpen}
        placement={placement}
        triggerSizes={triggerSizes}
        zIndex={zIndex}
        onClick={onTooltipClick}
      >
        {tooltipContent}
      </UI.TooltipContainer>
    </UI.TooltipWrapper>
  );
};

export default Tooltip;
