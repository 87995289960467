import Tooltip from '@core/UI/Tooltip';

import * as UI from './Share.styles';
import TooltipContent from './components/TooltipContent';
import { IShareProps } from './types';

const Share = (props: IShareProps) => {
  const { link, socials, tooltipPlacement } = props;

  return (
    <>
      <Tooltip
        placement={tooltipPlacement || 'bottomRight'}
        tooltipContent={<TooltipContent link={link} socials={socials} />}
        trigger="click"
        zIndex={10}
      >
        <UI.ShareButton title="Поделиться">
          <UI.ShareIcon />
        </UI.ShareButton>
      </Tooltip>
    </>
  );
};

export default Share;
