import { forwardRef } from 'react';

import { getPublicFileUrl } from '@core/utils/downloadFile';

import * as UI from './Card.styles';
import { ICardProps } from './types';

const Card = forwardRef<HTMLDivElement, ICardProps>((props, ref) => {
  const { backgroundImageId, children, ...args } = props;

  return (
    <UI.Card ref={ref} {...args}>
      {children}
      <UI.Image alt="" src={getPublicFileUrl(backgroundImageId)} />
    </UI.Card>
  );
});

export default Card;
